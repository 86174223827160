
import {computed, onMounted, reactive, ref} from "vue";
import {makeRequest, METHODS} from "@/utils/apiUtils";
import {canchasState} from "@/store/mantenedores.store";
import UITextSelect from "@/components/UI/UITextSelect.vue";
import {Bar} from 'vue-chartjs';
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js';
import {userState} from "@/store/user.store";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "Graph5",
  components: {
    UITextSelect,
    Bar
  },
  setup() {
    const user = computed(() => userState.value);
    const colors = [
      '#b71a4c',
      '#0000FF',
      '#008000',
      '#808000',
      '#FA8072',
      '#FF0000',
      '#000080',
      '#FF00FF',
      '#800080',
      '#00FFFF',
      '#CD5C5C',
      '#008000',
      '#FA8072',
      '#800080',
      '#FF0000',
      '#000080',
      '#FF00FF',
      '#0000FF',
      '#00FFFF',
      '#CD5C5C',
      '#008000',
      '#FA8072',
      '#800080',
      '#808000',
      '#FF0000',
      '#000080',
      '#FF00FF',
      '#0000FF',
      '#00FFFF',
      '#808000',
      '#CD5C5C',
      '#008000',
      '#FA8072',
      '#00FFFF',
      '#800080',
      '#FF0000',
      '#000080',
      '#FF00FF',
      '#0000FF',
      '#808000',
    ];
    const hasStatsData = ref(false);
    const isLoading = ref(false);
    // const colors = ['#CD5C5C', '#008000', '#FA8072', '#800080',  '#FF0000', '#FF00FF', '#000080', '#0000FF', '#00FFFF', '#808000', '#FFFF00', '#CD5C5C', '#008000', '#FA8072', '#800080',  '#FF0000', '#FF00FF', '#000080', '#0000FF', '#00FFFF', '#808000', '#FFFF00'];
    const items = ref<{ text: string, value: number }[]>([]);
    const chartData = reactive<{ labels: string[], datasets: { label: string, backgroundColor: string, data: number[] }[] }>({
      labels: [],
      datasets: []
    });
    const canchas = ref<{id: string, label: string }[]>([]);
    const sums = ref<number[]>([]);
    function formatNum(num: number) {
      return num ? +(num as number).toFixed(2) : 0;
    }
    const requestStats = async (id?: string) => {
      if (!id) {
        hasStatsData.value = false;
        return;
      }
      try {
        isLoading.value = true;
        const url = `stats/stock-de-productos-por-proveedor-por-cancha?cancha=${id}`;
        const response = await makeRequest<any>(METHODS.GET, url, true);
        hasStatsData.value = true;
        items.value = [];
        chartData.labels = [];
        if (chartData.datasets.length) chartData.datasets = [];
        console.log(response);
        const { stats, especies } = response.payload;
        const totals: number[] = [];
        chartData.labels = especies.map((el: any) => el.descripcion);
        stats.forEach((el:any, i: number) => {
            const item = {
              label: el.nombreProveedor,
              backgroundColor: colors[Math.floor(Math.random() * colors.length)],
              data: el.especies.map((a: any) => formatNum(a.qty))
            };
            el.especies.forEach((esp:any, j:number) => {
              totals[j] ??= 0;
              totals[j] += esp.qty;
            });
            chartData.datasets.push(item);
        });
        sums.value = totals;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      if (user.value?.cancha !== 'ALL') {
        canchas.value = canchasState.value.filter((el) => el._id === user.value?.cancha).map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion }));
      } else {
        canchas.value = canchasState.value.map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion }));
      }
    });

    return {
      canchas,
      requestStats,
      hasStatsData,
      isLoading,
      chartData,
      items,
      sums
    };
  }
};
