
import {computed, onMounted, reactive, ref} from "vue";
import {makeRequest, METHODS} from "@/utils/apiUtils";
import {canchasState} from "@/store/mantenedores.store";
import UITextSelect from "@/components/UI/UITextSelect.vue";
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import {userState} from "@/store/user.store";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "Graph1",
  components: {
    UITextSelect,
    Bar
  },
  setup() {
    const user = computed(() => userState.value);
    const hasStatsData = ref(false);
    const isLoading = ref(false);
    // const colors = ['#CD5C5C', '#008000', '#FA8072', '#800080',  '#FF0000', '#FF00FF', '#000080', '#0000FF', '#00FFFF', '#808000', '#FFFF00', '#CD5C5C', '#008000', '#FA8072', '#800080',  '#FF0000', '#FF00FF', '#000080', '#0000FF', '#00FFFF', '#808000', '#FFFF00'];
    const items = ref<{text: string, value: number}[]>([])
    const chartData = reactive<{ labels: string[], datasets: { label: string, backgroundColor: string, data: number[]}[]}>({
      labels: [],
      datasets: [
        {
          label: 'volumen',
          backgroundColor: '#f87979',
          data: []
        },
      ]
    });
    const sum = computed(() => chartData.datasets[0].data.reduce((a, b) => a + b, 0));
    const canchas = ref<{id: string, label: string }[]>([]);
    const requestStats = async (id?: string) => {
      hasStatsData.value = false;
      if (!id) {
        return;
      }
      try {
        isLoading.value = true;
        const url = `stats/stock-de-productos-por-cancha?cancha=${id}`;
        const response = await makeRequest<any>(METHODS.GET, url, true );
        hasStatsData.value = true;
        items.value = [];
        chartData.labels = [];
        chartData.datasets[0].data = [];
        response.payload.forEach((el: any) => {
          const num =  +((el.qty  as number).toFixed(2));
          chartData.labels.push(el.producto.descripcion);
          chartData.datasets[0].data.push(num);
          items.value.push({ text: el.producto.descripcion, value: num });
        });
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      if (user.value?.cancha !== 'ALL') {
        canchas.value = canchasState.value.filter((el) => el._id === user.value?.cancha).map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion }));
      } else {
        canchas.value = canchasState.value.map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion }));
      }
    });

    return {
      canchas,
      requestStats,
      hasStatsData,
      isLoading,
      chartData,
      items,
      sum
    };
  }
};
