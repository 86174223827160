
import { computed, reactive, ref } from 'vue';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { canchasState } from '@/store/mantenedores.store';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import UITextInput from "@/components/UI/UITextInput.vue";
import UITextSelect from "@/components/UI/UITextSelect.vue";
import UIButton from "@/components/UI/UIButton.vue";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'Graph1',
  components: {
    UIButton,
    UITextInput,
    Bar,
    UITextSelect
  },
  setup() {
    const hasStatsData = ref(false);
    const isLoading = ref(false);
    const date = ref('');
    // const colors = ['#CD5C5C', '#008000', '#FA8072', '#800080',  '#FF0000', '#FF00FF', '#000080', '#0000FF', '#00FFFF', '#808000', '#FFFF00', '#CD5C5C', '#008000', '#FA8072', '#800080',  '#FF0000', '#FF00FF', '#000080', '#0000FF', '#00FFFF', '#808000', '#FFFF00'];
    const items = ref<{ text: string; value: number }[]>([]);
    const chartData = reactive<{ labels: string[]; datasets: { label: string; backgroundColor: string; data: number[] }[] }>({
      labels: [],
      datasets: [
        {
          label: 'volumen',
          backgroundColor: '#f87979',
          data: []
        }
      ]
    });
    const tipoCancha = ref('');
    const tipoCanchas = [
      { id: 'ALL', label: 'TODAS' },
      { id: 'CANCHA', label: 'CANCHA' },
      { id: 'PLANTA', label: 'PLANTA'}
    ];
    const canchas = computed(() => canchasState.value.map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion })));
    const requestStats = async () => {
      if (!date.value || !tipoCancha.value) {
        return;
      }
      hasStatsData.value = false;
      try {
        isLoading.value = true;
        const url = `stats/cantidad-mensual-de-compras-por-cancha?date=${date.value}&tipo=${tipoCancha.value}`;
        const response = await makeRequest<any>(METHODS.GET, url, true);
        hasStatsData.value = true;
        items.value = [];
        chartData.labels = [];
        chartData.datasets[0].data = [];
        response.payload.forEach((el: any) => {
          const num = +(el.qty as number).toFixed(2);
          const text = el.cancha.codigo + ' ' + el.cancha.descripcion;
          chartData.labels.push(text);
          chartData.datasets[0].data.push(num);
          items.value.push({ text, value: num });
        });
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      canchas,
      requestStats,
      hasStatsData,
      isLoading,
      chartData,
      items,
      date,
      tipoCanchas,
      tipoCancha
    };
  }
};
