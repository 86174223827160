
import { computed, onMounted, reactive, ref } from 'vue';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { canchasState } from '@/store/mantenedores.store';
import UITextSelect from '@/components/UI/UITextSelect.vue';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { userState } from '@/store/user.store';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'Graph1',
  components: {
    UITextSelect,
    Bar
  },
  setup() {
    const colors = [
      '#CD5C5C',
      '#008000',
      '#800080',
      '#FF0000',
      '#000080',
      '#FA8072',
      '#FF00FF',
      '#0000FF',
      '#00FFFF',
      '#808000',
      '#FFFF00',
      '#CD5C5C',
      '#008000',
      '#FA8072',
      '#800080',
      '#FF0000',
      '#000080',
      '#FF00FF',
      '#0000FF',
      '#00FFFF',
      '#808000',
      '#FFFF00'
    ];
    const user = computed(() => userState.value);
    const hasStatsData = ref(false);
    const isLoading = ref(false);
    const items = ref<{ text: string; value: number }[]>([]);
    const canchas = ref<{ id: string; label: string }[]>([]);
    const chartData = reactive<{ labels: string[]; datasets: { label: string; backgroundColor: string; data: number[] }[] }>({
      labels: [],
      datasets: []
    });
    const requestStats = async (id?: string) => {
      function formatNum(num: number) {
        return num ? +(num as number).toFixed(2) : 0;
      }
      if (!id) {
        hasStatsData.value = false;
        return;
      }
      try {
        isLoading.value = true;
        const url = `stats/stock-en-ruma-por-antiguedad?cancha=${id}`;
        const response = await makeRequest<any>(METHODS.GET, url, true);
        hasStatsData.value = true;
        chartData.labels = [
          'Menos de 10 días',
          'Entre 10 y 15 días',
          'Entre 16 y 30 días',
          'Entre 31 y 60 días',
          'Entre 61 y 90 días',
          'Entre 91 y 120 días',
          'Entre 121 y 150 días',
          'Entre 151 y 180 días',
          'Entre 181 y 210 días',
          'Entre 211 y 240 días',
          'Entre 241 y 270 días',
          'Entre 271 y 360 días',
          'Sobre 360 días'
        ];
        response.payload.forEach((el: any, i: number) => {
          chartData.datasets.push({
            label: el.especie.descripcion,
            backgroundColor: colors[i],
            data: [
              formatNum(el.qty.menosde10),
              formatNum(el.qty.entre10y15),
              formatNum(el.qty.entre16y30),
              formatNum(el.qty.entre31y60),
              formatNum(el.qty.entre61y90),
              formatNum(el.qty.entre91y120),
              formatNum(el.qty.entre121y150),
              formatNum(el.qty.entre151y180),
              formatNum(el.qty.entre181y210),
              formatNum(el.qty.entre211y240),
              formatNum(el.qty.entre241y270),
              formatNum(el.qty.entre271y360),
              formatNum(el.qty.sobre360)
            ]
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      if (user.value?.cancha !== 'ALL') {
        canchas.value = canchasState.value
          .filter((el) => el._id === user.value?.cancha)
          .map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion }));
      } else {
        canchas.value = canchasState.value.map((el) => ({ id: el._id, label: el.codigo + ' ' + el.descripcion }));
      }
    });

    return {
      canchas,
      requestStats,
      hasStatsData,
      isLoading,
      items,
      chartData
    };
  }
};
